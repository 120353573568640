import React from 'react';
import { Table, List, ThemeIcon, Spoiler } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import ibironke from '../../assets/images/departments/ana/ibironke.png';
import adesida from '../../assets/images/departments/ana/adesida.png';
import agbamu from '../../assets/images/departments/ana/agbamu.png';
import alabi from '../../assets/images/departments/ana/alabi.png';
import asiyanbi from '../../assets/images/departments/ana/asiyanbi.png';
import dada from '../../assets/images/departments/ana/dada.png';
import motunrayo from '../../assets/images/departments/ana/motunrayo.png';
import ohagwu from '../../assets/images/departments/ana/ohagwu.png';
import olatosi from '../../assets/images/departments/ana/olatosi.png';
import oyabola from '../../assets/images/departments/ana/oyabola.png';
import rotimi from '../../assets/images/departments/ana/rotimi.png';
import uzoma from '../../assets/images/departments/ana/uzoma.png';
import akanmu from '../../assets/images/departments/ana/akanmu.png';

const anaesthesia = () => {
  const staffs = [
    {
      name: 'Mr. Oladimeji Ayobami Adetunji',
    },
    {
      name: 'Miss. Kafayat Olayemi Jinadu',
    },
    {
      name: 'Mr. Chibuike Bright Ibeh',
    },
  ];

  const services = [
    {
      name:
        'General Anaesthesia: This forms the majority of our services for elective and emergency procedures. ',
    },
    {
      name:
        'Regional Anaesthesia comprising of Subarachnoid block (Spinal Anaesthesia), Epidural block, Combined Spinal Epidural (CSE), Caudal block, Pediatric epidural, Biers block. They are performed as sole anaesthetic techniques or as part of a general anaesthetic.',
    },
    { name: 'Obstetric analgesia service for high-risk parturient.' },
    {
      name:
        'Monitored Anaesthesia Care (MAC) is usually deployed to patients who do not require anaesthesia but need close monitoring during the procedure e.g. pace maker insertion, drainage of subdural haematoma, tracheostomy etc.  ',
    },

    {
      name:
        'Procedural Sedation Analgesia (Conscious Sedation) usually for children that require some form of intervention for diagnostic or therapeutic purposes e.g. intrathecal injection of chemotherapy agents, bone marrow aspiration, radiological and radiotherapy procedures ',
    },
    {
      name:
        'Critical Care Medicine is provided in a 4-bed well-equipped Intensive Care Unit which provides ventilatory and circulatory support as well as critical care interventions.',
    },
    { name: 'Transport and transfer of critically ill patients' },
    {
      name:
        'Resuscitation: We are the hospital’s team lead in cardiopulmonary resuscitation (CPR) and provide service to ALL areas of the hospital.',
    },
  ];

  const nonRows = staffs.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
    </tr>
  ));
  return (
    <Layout pageTitle='Department of Anaesthesia | LUTH'>
      <NavOne />
      <PageHeader title='Department of Anaesthesia' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      DEPARTMENT OF ANAESTHESIA
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The history of the Department of Anaesthesia dates back
                        to 1962 when the Lagos Medical School and Lagos
                        University Teaching Hospital were established. Clinical
                        anaesthesia commenced in August 1962 with the arrival of
                        a group of four Canadian doctors headed by the late
                        Prof. Shirley Flemming. It became the first
                        academic/clinical Department of Anaesthesia in Nigeria.
                        Prof V. Fowler became the second Head of Department in
                        1968. During his tenure, the residency training
                        programme in anaesthesia commenced in 1972. The headship
                        of the department transferred to Prof D.J.O.
                        Ffoulkes-Crabbe in 1978 and has been held by a number of
                        distinguished anaesthetists including Prof O.T. Kushimo.
                        <br />
                        <br />
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 2 }}
                        >
                          Training in the Department
                        </h2>
                        The department is involved in training of undergraduate
                        medical and dental students in resuscitation, safe
                        anaesthesia techniques and critical care medicine. There
                        are presently two postgraduate programmes run in the
                        department; Diploma in Anaesthesia of the University of
                        Lagos (PGDA) and the Residency Training Programmes for
                        the Fellowship of the National Postgraduate Medical
                        College (FMCA) and the West African College of Surgeons
                        (FWACS). The Department has been in the forefront of
                        postgraduate anaesthesia training being the first
                        Department in the country to offer the University
                        Postgraduate Diploma in Anaesthesia (PGDA) thereby
                        contributing to the provision of middle-level manpower.
                        The School of Anaesthetic Technician (SOAT) runs a 3
                        year training course culminating in a Diploma
                        (Anaesthesia technician). Presently there are 25
                        students enrolled. Coordinator: Dr. Adeniyi Adesida
                        Deputy Coordinator: Dr. Pamela Agbamu Our departmental
                        staff is also involved in the training of Theatre Nurses
                        and Midwives, Accidents and Emergency Nurses and
                        Ophthalmic Nurses. We organize training on
                        Cardiopulmonary Resuscitation (CPR) for hospital staff
                        as well as corporate bodies and Institutions. In 2017,
                        the Department was accredited as a training center for a
                        post-fellowship training programme in paediatric
                        anaesthesia by the World Federation of Societies of
                        Anaesthesiologist (WFSA). The Department of Anaesthesia
                        is committed to maintaining excellence in anaesthesia
                        through quality education, service and research.
                        <br />
                        <br />
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 2 }}
                        >
                          THEATRE COMPLEXES
                        </h2>
                        <b>Modular Theatre:</b> The main theatre complex is the
                        modular theatre complex which has 6 suites that are
                        functional and well equipped as well as a recovery room
                        or post anaesthetic care room which can accommodate more
                        than 10 patients . <br /> Obstetric theatre: situated in
                        the newly renovated obstetric complex comprising of 2
                        theatre suites. <br /> Obstetric Anaesthesia
                        Coordinator: Dr. Pamela Agbamu <br />
                        Deputy:Dr. Uzoma Ifezue <br />
                        <br />
                        <b>KidsOR/ SmileTrain theatre:</b> is situated on the
                        1st floor of the Olikoye Ransome Kuti Paediatric
                        complex. It comprises of 2 theatre suites which are
                        exclusively for children. <br />
                        <br />
                        <b>Ophthalmic Theatre:</b> is situated on the 1st floor
                        of Guinness Eye Centre. It consists of 2 operating
                        theatres dedicated to eye surgeries.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ibironke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Ibironke Desalu</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, PG(DA), MD, FWACS, FMCA, FMCEM
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant, Head of Department
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Paediatric Anaesthesia Cleft Anaesthesia
                            Resuscitation
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olatosi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. John Olutola Olatosi (Assoc Prof)</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS, FNAMed
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neuro-Anaesthesia Critical Care                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akanmu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olanrewaju Nurudeen Akanmu</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB, PGDA, MPH, FMCA
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Pain/Regional Anaesthesia Cardiothoracic Anaesthesia                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oyabola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oyebola Olubodun Adekola ( Assoc Prof)</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, M.Sc., FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiothoracic Anaesthesia, Pain Management.                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={dada} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr.Olufemi Israel O. Dada</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB, DA, (WACS), FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span> Ear,
                            Nose and Throat (ENT) Anaesthesia Intensive care                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adesida} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Adeniyi Abiodun Adesida</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FMCA
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neuroanaesthesia, Intensive Care Orthopaedic
                            Anaesthesia                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={agbamu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Pamela Onorame Agbamu</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant Special Grade 2
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Obstetrics and Gynaecology Otorhinolaryngology                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={rotimi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Muyiwa Kayode Rotimi </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant Special Grade 2
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neuroanaesthesia Cardiothoracic Resuscitation
                            Critical Care                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={asiyanbi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Gabriel Kolawole Asiyanbi</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Paediatric Anaesthesia,                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={uzoma} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Uzoma Chinedu Ifezue</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Locum consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Obstetrics, Critical Care, Regional blocks                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={motunrayo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Motunrayo Adebukunola Oladimeji</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BSc, MBchB, PGDA, MPH, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Locum consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Paediatric anaesthesia Otorhinolaryngology
                            Cardiothoracic anaesthesia                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={alabi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olubukola Iretiogo Alabi</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, DA, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Locum consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neuroanaesthesia, Critical Care                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ohagwu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ijeoma Chinenye Ohagwu</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Locum consultant
                            <br />
                            <span className='detail-head'>Specialty:</span> Oral
                            & Maxillofacial, Urology, Paediatric anaesthesia                           
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Non Academic Staff
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                          </tr>
                        </thead>
                        <tbody>{nonRows}</tbody>
                      </Table>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:anaesthesia@luth.gov.ng'>                        
                        anaesthesia@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default anaesthesia;
